/* src/components/ModelListItem.css */

.model-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-radius: 8px;
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    max-width: 100%;
    box-sizing: border-box;
   
}

.model-list-item:hover {
    background-color: #f9f9f9;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  
}

.model-list-item label {
   cursor: pointer;
  
}

.model-switch {
    margin-left: 20px;
    flex-shrink: 0;
}

.model-name {
    font-size: 1rem;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
    margin-left: 10px;
}
