/* src/global.css */

:root {
  --primary-light: #0051a8;
  --primary-dark: #85c0ff;
  --secondary-color: #6c757d;
  --background-color: #f8f9fa;
  --background-color-dark: #4f4f4f;
  --background-color-darker: #333333;
  --background-gradient: linear-gradient(315deg, #85c0ff 0%, #0051a8 100%);
  --background-gradient-light: linear-gradient(
    135deg,
    #ffffff 50%,
    #57a8ff 100%
  );
  --background-gradient-dark: linear-gradient(135deg, #0051a8 0%, #000000 100%);
  --card-background-color: #fafafa;
  --card-background-color-dark: #161616;
  --card-selected-bg: #57a8ff;
  --card-border-radius: 10px;
  --card-padding: 20px;
  --card-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  --hover-background-color: #e9ecef;
  --transition-duration: 0.3s;
  --primary: #0d6efd;
  --dark: #000000;
  --light: #ffffff;
}

body {
  margin: 0;
  font-family: "Roboto", "Open Sans", "Lato", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* src/App.css */

.app.light {
  background-color: #ffffff;
  color: #000000;
}

.app.dark {
  background-color: #262626;
  color: #ffffff;
}
/* Adjust height for mobile screens */
.app {
  overflow: auto; /* Default overflow hidden */
  padding-top: 60px;
}

@media (max-width: 768px) {
  .app {
    overflow: auto; /* Allow scrolling for smaller screens */
  }
}

/* Ensure child elements are sized correctly */
body,
html {
  overflow: auto;
}
.scroll-buttons {
  position: fixed;
  top: 50%;
  right: 3%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}

.scroll-buttons button {
  background: rgba(0, 81, 168, 0.3);

  backdrop-filter: blur(10px); /* Frosted glass effect */
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 12px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5); /* Soft glow effect */
}

.scroll-buttons button:hover {
  background: rgba(0, 81, 168, 1); /* Stronger reflection on hover */
  transform: scale(1.1);
}

.scroll-buttons .scroll-down {
  display: block;
}
