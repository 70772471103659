/* ContextForm.css */

.context-form {
    padding: 0;
    border-radius: var(--card-border-radius);
    box-shadow: var(--card-shadow);
    transition: transform var(--transition-duration);
    margin-top: 20px;
    margin-bottom: 0;
}

.context-form .form-group {
    margin-bottom: 10px;
    position: relative;
}

.context-form .card-body {
    padding: 10px;
}

.context-form .card-title {
    margin-top: 10px;
    margin-bottom: 10px;
}

