.loading-overlay {
  position: fixed; /* Position relative to the viewport */
  top: 50%; /* Center vertically */
  left: 0; /* Align with the left edge */
  transform: translateY(-50%); /* Center vertically using translation */
  display: flex; /* Use flex for vertical alignment */
  justify-content: center; /* Center content horizontally within the overlay */
  align-items: center; /* Align content vertically */
  width: 100%; /* Span the full width of the viewport */
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  z-index: 1050; /* Ensure it appears above other elements */
  animation: fadeIn 0.3s ease-in-out; /* Smooth fade-in animation */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.loading-overlay .loading-content {
  text-align: center;
  color: #555; /* Default text color */
  padding: 0; /* Remove padding */
}

.loading-overlay .loading-icon {
  font-size: 2rem;
  color: #007bff; /* Spinner color */
  animation: spin 1s linear infinite; /* Rotating animation */
  font-weight: 100; /* Make spinner thinner */
}

.loading-message {
  font-size: 1rem;
  margin: 10px 0;
  font-weight: 500;
}

.loading-time {
  font-size: 0.9rem;
  margin-top: 5px;
  opacity: 0.8;
}

/* Animations */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
