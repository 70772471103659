.dropdown-content {
  height: 10vh;
}

.custom-modal {
  top: 20% !important; /* Juster verdien av top for å flytte modalen */
  z-index: 1050;
}

.expanded {
  opacity: 1;
  position: absolute;
  transition: all 1s ease;
  width: 100%;
  transform: translateY(0);
}

.content-table-list {
  position: relative;
}

.content-table-list tr.expanded-row {
  display: table-row;
  background-color: #f9f9f9; /* Valgfritt: lys bakgrunnsfarge for tydeligere skille */
}

.content-table-list td.expanded-content {
  padding: 15px;
  font-size: 0.9rem;
  max-height: 200px; /* Sett en maks høyde for feltet */
  max-width: 100%; /* Sett en maks bredde for feltet */
  overflow: auto; /* Gjør innholdet både vertikalt og horisontalt scrollbart hvis det overstiger maks dimensjoner */
  white-space: normal; /* Tillat tekstbryting innenfor feltet */
}

.selected-counter {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 15px;
  display: inline-block;
  margin-right: 10px;
  font-size: 1rem;
}

.view-icon-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-icon-button:hover {
  background-color: #0056b3;
}

.modal-center {
  max-width: 500px;
  margin: auto;
}
