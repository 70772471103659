/* DialogListItem.css */

.thread-list-item {
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
  margin-bottom: 10px;
}

.thread-list-item:hover {
  background-color: #f8f9fa;
}

.thread-list-item.selected {
    background-color: var(--card-selected-bg) !important;
    color: #000000 !important;
    box-shadow: inset 4px 4px 4px #9CD4F2, inset -4px -4px 3px #85C0FF;
}

.thread-icon {
  margin-right: 10px;
  font-size: 1.5rem;
  color: var(--primary);
}

.thread-details {
  flex-grow: 1;
  word-break: break-word;
}

.thread-content-badges .badge {
  margin-right: 5px;
  margin-bottom: 5px;
}
.thread-details p {
    font-size: 0.75rem;
   }