/* Generell styling for modal */
.content-modal .modal-body {
    max-height: 400px;
    overflow-y: auto;
}

.content-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

/* Responsiv styling for større skjermer */
@media (min-width: 768px) {
    .content-modal {
        max-width: 600px; /* Fast bredde for større skjermer */
    }
}

/* Responsiv styling for mobilskjermer */
@media (max-width: 767px) {
    .content-modal {
        max-width: 90%; /* Bruker 90% av skjermbredden på mobile enheter */
    }
}

/* Forbedret styling av søkefeltet */
.content-modal .search-input {
    width: 100%; /* Full bredde */
    padding: 10px; /* Mer padding for større felt */
    margin-bottom: 15px; /* Mer mellomrom under */
    border: 2px solid #007bff; /* Blå kant for å gjøre det mer synlig */
    border-radius: 5px; /* Rundere hjørner */
    font-size: 1.1rem; /* Større tekst for bedre lesbarhet */
}

/* Styling for tittel og dato */
.content-list-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; /* Juster tittel til venstre */
    padding: 10px;
    word-break: break-word;
}

/* Tittelen skal være til venstre, i fet skrift */
.content-title {
    font-weight: bold;
    width: 100%; /* Sørg for at tittelen tar hele bredden */
    text-align: left; /* Juster tittelen til venstre */
}

.content-description {
    width: 100%; /* Sørg for at tittelen tar hele bredden */
    text-align: left; /* Juster tittelen til venstre */
}

/* Datoen plasseres nederst til høyre */
.content-date {
    font-size: 0.9rem;  
    margin-top: 5px;
    text-align: right; /* Juster datoen til høyre */
    width: 100%; /* Sørg for at datoen tar hele bredden */
    align-self: flex-end; /* Plasser datoen nederst til høyre */
}


.content-list-item.active {
    background-color: #007bff !important;
    color: white !important;
}
