.content-form {
  padding: 0;
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-shadow);
  margin-top: 20px;
  max-width: 100%; /* Begrens bredden til kortet */
  max-height: 80vh;
  overflow-y: auto; /* Vertikal rull */
}

.input-field {
  width: 100%;
  overflow-y: auto; /* Vertikal rull */
  overflow-x: hidden; /* Forhindrer horisontal rull */
  max-height: 80vh;
}
/* ContentForm.css */
.input-textarea {
  height: 200px; /* Fixed height for textarea */
  overflow-y: auto;
}
