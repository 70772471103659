.classification-info {
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #ffffff;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    padding: 20px;
}

.classification-info h4 {
    font-weight: bold;
    color: #333;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
    text-align: center;
    margin-bottom: 20px;
}

.classification-info table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.classification-info table th,
.classification-info table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
}

.classification-info table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.clickable-row {
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.clickable-row:hover {
    background-color: #f1f1f1;
}

.chevron-icon {
    float: right;
    margin-left: 10px;
    color: #666;
    transition: transform 0.3s ease;
}

.collapse-content {
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 10px;
}

.collapse-content p {
    margin: 0;
    color: #555;
    line-height: 1.6;
}

.classification-info .classification-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #444;
    text-align: center;
    margin-bottom: 10px;
}

.classification-info .selected-file {
    font-size: 1rem;
    color: #555;
    background-color: #f9f9f9;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
    display: inline-block;
}


.classification-info table tr.expanded-row td {
    padding: 15px;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
}

/* Håndtering av store mengder innhold */
.expanded-content {
    max-height: 200px;
    overflow-y: auto;
    white-space: normal;
}