/* src/pages/Profile.css */



.profile-header {
    width: 100%;
    padding: 20px;
    background: var(--primary-color);
    color: white;
    text-align: center;
}

.profile-card {
    width: 100%;
    max-width: 800px;
    border-radius: var(--card-border-radius);
    overflow: hidden;
    background-color: var(--card-background-color);
    box-shadow: var(--card-shadow);
    transition: transform var(--transition-duration), box-shadow var(--transition-duration);
    margin-top: 50px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-details {
    padding: var(--card-padding);
    text-align: center;
}

.profile-details .card-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    font-size: 1.1rem;
}

.profile-details .card-text svg {
    margin-right: 10px;
    color: var(--secondary-color);
}

.profile-details .card-text strong {
    margin-right: 5px;
}

.profile-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    width: 100%;
}

.profile-actions button {
    font-size: 1rem;
    padding: 10px 20px;
    transition: background-color var(--transition-duration), transform var(--transition-duration);
}

.profile-actions button:hover {
    background-color: var(--primary-color-dark);
    transform: translateY(-2px);
}
