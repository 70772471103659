/* src/components/NavBar.css */

.navbar {
  background-color: #343a40;
  border-bottom: 2px solid #007bff;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff !important;
}

.nav-link {
  color: #ffffff !important;
  font-size: 1rem;
  margin-right: 1rem;
  transition: color 0.2s ease-in-out;
  text-transform: uppercase;
  font-weight: 600;
}

.nav-link:hover {
  color: #007bff !important;
}

.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-toggler-icon {
  font-size: 1.25rem;
  color: #ffffff;
}

.nav-link.active {
  font-weight: bold;
  color: var(--primary-dark) !important;
  text-decoration: underline;
}

/* Fjern uønsket outline på fokus */
.dropdown-item:focus {
  outline: none;
  box-shadow: none;
}

/* Sørg for at bakgrunn og farge endres på hover og aktiv */
.dropdown-item.active,
.dropdown-item:hover {
  background-color: #007bff;
  color: #ffffff;
}

/* Forhindre rare markeringer på enkelte nettlesere */
.dropdown-item:active {
  background-color: #0056b3;
  color: #ffffff;
}

/* Fjern ::marker fra alle dropdown- eller listeelementer */
.dropdown::marker,
.nav-item::marker {
  content: "";
  display: none;
}
