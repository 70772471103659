.button-group {
    display: flex;
    gap: 1px; /* Reduserer mellomrom mellom knappene */
    justify-content: flex-end; /* Justerer knappene til høyre */
    align-items: center;
}

.button-group-item {
    display: inline-flex;
    align-items: center;
}



