

.login-page {
  background: var(--background-gradient);
  height: 100vh; 
  overflow: hidden; 
}

.login-card {
  border-top-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px);
  transition: transform var(--transition-duration),
    box-shadow var(--transition-duration);
    display: inline-block; 
}

.logo {
  width: 200px;
  height: auto;
-webkit-animation: spin 5s infinite;
animation: spin 5s infinite;
}
@-webkit-keyframes spin{
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(-360deg);
  }
}

@keyframes spin{
  from {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  
  to {
    -moz-transform: rotateY(-360deg);
    -ms-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
  }
}