/* NewDialogForm.css */

.new-thread-form-container {
    margin: 20px;
}

.new-thread-form {
  
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 20px;
}

.new-thread-form h3 {
    margin-bottom: 20px;
 
}

.new-thread-form .form-group {
    margin-bottom: 1rem;
}

.new-thread-form label {
    font-weight: bold;
   
}

.new-thread-form .form-control {
    border-radius: 0.375rem;
    border: 1px solid #ddd;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}



.new-thread-form .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.new-thread-form .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

.new-thread-form .selected-content-badges {
    display: flex;
    flex-wrap: wrap;
}

.new-thread-form .badge {
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: #6c757d;
    color: white;
}

.new-thread-form .badge:hover {
    background-color: #5a6268;
}
