/* ModelList.css */

.model-list {
    background-color: var(--card-background-color);
    border-radius: var(--card-border-radius);
    box-shadow: var(--card-shadow);
    max-height: 100%;
    overflow-y: auto;
    border: 1px solid #ddd;
    max-width: 100%;
    box-sizing: border-box;
   margin-bottom: 1rem;
}
