/* CSS for DeleteModal Component */
.custom-modal-width {
    max-width: 500px; /* Adjust the width as needed */
    margin: auto;
}
.modal-center {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}
