/* ContentList.css */

.content-list {
  max-height: 100vh; /* Subtract padding or header height if needed */
  overflow-y: auto;
  padding-right: 10px; /* Add some padding to avoid content being cut off */
  margin-top: 20px;
}

.content-list-item {
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
  border: 1px solid var(--hover-background-color);
  margin-bottom: 10px;
  border-radius: var(--card-border-radius);
  padding: 15px;
  display: flex;
  align-items: center;
  max-width: 100%;
  box-sizing: border-box;
}
.content-list-item:hover {
  background-color: #f8f9fa;
  box-shadow: var(--card-shadow);
}

.content-list-item h5 {
  margin-bottom: 0;
}

.content-list-item p {
  margin-bottom: 0;
}
