.message-item {
  padding: 15px;
  margin-bottom: 15px;
  transition: background-color 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 10px; /* Økt avrunding av kantene */
  max-width: 100%;
  box-sizing: border-box;
  word-wrap: break-word;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Legg til skygge */
}

.message-user {
  background-color: #f8f9fa;
}

.message-assistant {
  background-color: white;
}

.message-item:last-child {
  margin-bottom: 0;
}

.message-item:hover {
  background-color: #e9ecef;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Større skygge ved hover */
}

.message-role {
  font-size: 0.9rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.message-content {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  flex: 1;
}

.message-icons {
  display: flex;
  align-items: center;
}

.message-icons svg {
  cursor: pointer;
  margin-left: 15px; /* Økt avstand mellom ikonene */
  transition: transform 0.3s ease, color 0.3s ease; /* Legg til hover-effekt */
}

.message-icons svg:hover {
  transform: scale(1.2); /* Gjør ikonet litt større ved hover */
  color: var(--primary); /* Endre farge ved hover */
}

.copied-icon {
  color: green;
}

.message-role {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Litt ekstra avstand under rollen */
}

.message-role svg {
  margin-right: 5px; /* Juster avstanden mellom ikonet og teksten */
}
