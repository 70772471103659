.classification-info {
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #ffffff;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 80%; /* Redusert for å unngå at det går utenfor skjermen */
    padding: 20px;
    overflow-x: hidden; /* Unngå uønsket horisontal scroll */
}

.classification-info h4 {
    font-weight: bold;
    color: #333;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
    text-align: center;
    margin-bottom: 20px;
}

.clickable-row {
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.clickable-row:hover {
    background-color: #f1f1f1;
}

.chevron-icon {
    float: right;
    margin-left: 10px;
    color: #666;
    transition: transform 0.3s ease;
}

.classification-info .classification-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #444;
    text-align: center;
    margin-bottom: 10px;
}

.classification-info .selected-file {
    font-size: 1rem;
    color: #555;
    background-color: #f9f9f9;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
    display: inline-block;
}

.fields {
    background-color: #e8f5e9;
}

.unmatchedFields {
    background-color: #fffde7;
}

.excludedFields {
    background-color: #ffebee;
}

/* Generell styling for nav-tabs */
.nav-tabs {
    border-bottom: 2px solid #ddd; /* Linje under tabs */
    display: flex;
    justify-content: flex-start; /* Juster tabs til venstre */
    margin-bottom: 15px;
}

/* Generell styling for nav-tabs */
.nav-tabs {
    border-bottom: 2px solid #ddd; /* Linje under tabs */
    display: flex;
    justify-content: flex-start; /* Juster tabs til venstre */
    margin-bottom: 15px;
}

/* Generell styling for nav-tabs */
.nav-tabs {
    border-bottom: 2px solid #ddd; /* Linje under tabs */
    display: flex;
    justify-content: flex-start; /* Juster tabs til venstre */
    margin-bottom: 15px;
}

/* Generell styling for nav-tabs */
.nav-tabs {
    border-bottom: 2px solid #ddd; /* Linje under tabs */
    display: flex;
    justify-content: flex-start; /* Juster tabs til venstre */
    margin-bottom: 15px;
}

/* Styling for individuelle linker */
.nav-tabs .nav-link {
    border: 1px solid #ddd; /* Legger til kantlinje */
    border-bottom: none; /* Fjern kantlinje under linken */
    margin-right: 5px; /* Litt mellomrom mellom tabs */
    padding: 10px 20px; /* Bedre klikkflate */
    color: #555; /* Mørk tekst */
    background-color: #f0f0f0; /* Lys grå bakgrunn */
    border-radius: 8px 8px 0 0; /* Runde hjørner på toppen */
    transition: all 0.3s ease; /* Smooth hover-effekt */
    font-weight: bold; /* Marker tabs */
    text-transform: uppercase; /* Store bokstaver */
    font-size: 1rem; /* Juster skriftstørrelse */
}

/* Hover-effekt */
.nav-tabs .nav-link:hover {
    background-color: #d6e0f5; /* Lys blå for hover */
    color: #003366; /* Mørk blå tekst */
}

/* Aktiv tab */
.nav-tabs .nav-link.active {
    color: #ffffff; /* Hvit tekst */
    background-color: #0047ab; /* Klar blå bakgrunn */
    border-color: #0047ab #0047ab #ddd; /* Match kantlinjer */
    border-radius: 8px 8px 0 0; /* Behold runde hjørner */
    font-weight: bold; /* Fremhev aktiv tab */
    font-size: 1rem; /* Øk skriftstørrelsen */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Legg til skygge for dybde */
}

/* Fix for nav-tabs for responsive screens */
@media (max-width: 768px) {
    .nav-tabs {
        justify-content: center; /* Midtstill tabs på små skjermer */
        flex-wrap: wrap; /* Tillat wrapping hvis det er nødvendig */
    }

    .nav-tabs .nav-link {
        padding: 8px 15px; /* Mindre padding for bedre plass på små skjermer */
        margin-bottom: 5px; /* Gi plass mellom linjer hvis tabs wrap */
    }
}



