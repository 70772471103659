/* General Button Style */
button {
  font-family: inherit;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Primary Button */
.primary-btn {
  padding: 12px 24px; /* Mer plass inni knappene */
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #0056d6;
  border: none;
  border-radius: 5px;
  transition: background 0.3s ease, transform 0.2s ease;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Mellomrom mellom ikon og tekst */
  white-space: nowrap; /* Hindrer tekstbryting */
}

.primary-btn:hover {
  background-color: #003c9d;
  transform: scale(1.05);
}

.button-wrapper:hover .hover-label {
  display: block;
  opacity: 1;
}

/* Secondary Button */
.secondary-btn {
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #198754;
  border: none;
  border-radius: 5px;
  transition: background 0.3s ease, transform 0.2s ease;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Mellomrom mellom ikon og tekst */
  white-space: nowrap; /* Hindrer tekstbryting */
}

.secondary-btn:hover {
  background-color: #145c39;
  transform: scale(1.05);
}

/* Option Button */
.option-btn {
  padding: 10px 20px;
  font-size: 0.9rem;
  font-weight: bold;
  color: #333;
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: background 0.3s ease, transform 0.2s ease;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Mellomrom mellom ikon og tekst */
  white-space: nowrap; /* Hindrer tekstbryting */
}

.option-btn:hover {
  background-color: #e0e0e0;
  transform: scale(1.05);
}

/* Cancel Button */
.cancel-btn {
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #dc3545;
  border: none;
  border-radius: 5px;
  transition: background 0.3s ease, transform 0.2s ease;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Mellomrom mellom ikon og tekst */
  white-space: nowrap; /* Hindrer tekstbryting */
}

.cancel-btn:hover {
  background-color: #a71d2a;
  transform: scale(1.05);
}

/* Focus Styles */
button:focus {
  outline: 3px solid rgba(0, 123, 255, 0.5);
  outline-offset: 2px;
}

button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.export-btn {
  padding: 12px 24px; /* Samme padding som de andre knappene */
  font-size: 1rem; /* Skriftstørrelse for lesbarhet */
  font-weight: bold; /* Tykk skrift for tydelighet */
  border-radius: 5px; /* Avrundede hjørner for et moderne utseende */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px; /* Mellomrom mellom ikon og tekst */
  background-color: #4caf50; /* Primær grønn farge */
  color: #fff; /* Hvit tekst for kontrast */
  border: none; /* Fjern kantlinje */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Animasjoner */
}

.export-btn:hover {
  background-color: #45a049; /* Mørkere grønn for hover */
  transform: scale(1.05); /* Litt større ved hover */
}

.export-btn:active {
  background-color: #388e3c; /* Enda mørkere grønn ved klikk */
}

.export-dropdown {
  margin-left: 10px;
}

.export-dropdown .dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 8px 12px;
  color: #333;
}

.export-dropdown .dropdown-item:hover {
  background-color: #f1f1f1;
  color: #0056d6;
}

/* Eksisterende knappestiler beholdes */

/* Spesifikk styling for "How it works"-knappen */
.question-btn {
  background-color: #17a2b8;
  color: #fff;
  padding: 12px 24px; /* Samme som de andre knappene */
  font-size: 1rem; /* Samme skriftstørrelse */
  font-weight: bold; /* Samme vekt */
  border-radius: 5px; /* Matchende avrundede hjørner */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px; /* Mellomrom mellom tekst og ikon */
  height: auto; /* Sørg for at høyden tilpasses automatisk */
  min-width: 120px; /* Juster til de andre knappene */
  max-width: fit-content; /* Unngå at knappen blir for bred */
}

.question-btn:hover {
  background-color: #138496;
}
