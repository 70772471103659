/* ContextInfo.css */

.context-info {
    margin-top: 20px;
}


/* Ny klasse for å gjøre valgt context mer synlig */
.selected-context-text {
    font-size: 1.25rem; /* Større tekst */
    font-weight: bold;  /* Fet tekst */
    color: #007bff;     /* Blå farge for synlighet */
    margin-left: 1rem;
    margin-right: 1rem; /* Litt mellomrom før edit-knappen */
}
