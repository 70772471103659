/* Responsiv tabell */
.generic-table-container .table-responsive {
  overflow-x: auto;

  position: relative; /* Kreves for absolutt posisjonering av overlay */
}

/* Interaktiv hover-effekt */
.generic-table-container table tbody tr:hover {
  background-color: var(--secondary-light);
  cursor: pointer;
}

/* Tittelrad */
.generic-table-container table th {
  background-color: #f5f5f5; /* Lys grå */
  color: #333; /* Mørk grå tekst */
  text-align: left;
  font-weight: bold;
}

/* Sorteringspeker */
.generic-table-container .sortable {
  cursor: pointer;
}

.generic-table-container .sortable span {
  margin-left: 0.5rem;
}

/* Meta-informasjon */
.generic-table-container .meta-info {
  font-size: 0.875rem;
  color: gray;
}

/* Handling-knapper justert for mindre plass */
.generic-table-container .action-buttons {
  display: flex;
  gap: 0.5rem;
}

.generic-table-container .action-buttons .danger-btn {
  color: var(--danger);
}

/* Farger og styling */
.generic-table-container table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.generic-table-container table th,
.generic-table-container table td {
  padding: 0.75rem;
  text-align: left;
  vertical-align: middle;
  border: 1px solid #dee2e6;
  word-break: break-word;
  min-width: 7rem;
}

.generic-table-container table tbody tr:nth-child(even) {
  background-color: #f8f9fa;
}

/* For små skjermer */
@media (max-width: 768px) {
  .generic-table-container .table-responsive {
    border: 0;
  }

  .generic-table-container table th,
  .generic-table-container table td {
    white-space: nowrap;
  }
}

/* Metadata-seksjon for utvidbare rader */
.generic-table-container .metadata-section {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  font-size: 0.9rem;
  line-height: 1.6;
  margin: 10px 0;
  border: 1px solid #dee2e6;
}

/* Rotasjon for Chevron-ikon */
.generic-table-container .rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

/* Styling for unknown values */
.unknown-value {
  color: #999;
  font-style: italic;
}

/* Stil for tabell i mørkt tema */
.generic-table.table-dark {
  border-color: var(--border-color-dark);
}

.generic-table th,
.generic-table td {
  transition: background-color 0.3s, color 0.3s;
}

/* Styling for table description */
.generic-table-container .table-description {
  font-size: 1rem;
  color: #555; /* Mørk grå tekst */
  margin-bottom: 1rem; /* Litt luft mellom beskrivelsen og tabellen */
  padding: 10px 15px; /* Innrykk for bedre lesbarhet */
  background-color: #f8f9fa; /* Lys bakgrunn */
  border-left: 4px solid #007bff; /* Farget linje for fremheving */
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Svak skygge */
}
