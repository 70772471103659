/* Flytende opplastingsknapp */
.floating-upload-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--primary);
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 9999; /* Increased value */
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.floating-upload-button:hover {
    background-color: var(--primary-dark);
    transform: scale(1.1);
}
