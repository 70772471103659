.add-button-container {
  display: flex;
  justify-content: right;
  margin-top: 20px;
}

.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.2em;
  text-align: center;
  padding: 20px;
}
.contentlist-slide {
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
  will-change: transform;
}

.contentlist-slide-hidden {
  transform: translateX(-100%);
}
