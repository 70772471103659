/* ContextList.css */

.context-list {
    max-height: 100vh;
    overflow-y: auto;
    padding-right: 10px; /* Add some padding to avoid content being cut off */
    margin-top: 20px;
}

.context-list h2 {
    margin-bottom: 20px;
}

.context-list-item {
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;
    border: 1px solid var(--hover-background-color);
    margin-bottom: 10px;
    border-radius: var(--card-border-radius);
    padding: 15px;
    display: flex;
    align-items: center;
    max-width: 100%;
    box-sizing: border-box;
}

.context-list-item:hover {
    background-color: #f8f9fa;
    box-shadow: var(--card-shadow);
}

.context-list-item h5 {
    margin-bottom: 0;
}

.context-list-item p {
    margin-bottom: 0;
}
