/* MessageList.css */

.messages-item-container {
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  box-sizing: border-box;
}

.form-group {
  margin-bottom: 1rem;
}

#newMessage {
  border-radius: 0 0.375rem 0.375rem 0;
}

.input-group-text {
  color: white;
  border-radius: 0 0.375rem 0.375rem 0;
}

.button-add {
  border-radius: 0 0.375rem 0.375rem 0;
}

.custom-badge {
  background-color: #3c80b6 !important;
  color: white !important;
  border-radius: 0.5rem; /* Økt border-radius for et mer avrundet utseende */
  padding: 0.4rem 0.8rem; /* Økt padding for å gjøre badges større og mer merkbare */
  transition: box-shadow 0.2s ease-in-out; /* Legger til en myk overgang for hover-effekten */
  box-shadow: none; /* Standardtilstand uten skygge */
}
#newMessage::placeholder {
  color: #aaa;
}
.message-container {
  word-break: break-word !important;
  overflow-y: auto;
}
.sticky-form {
  background: rgba(255, 255, 255, 0.2); /* Light transparent */
  border-top: 3px solid var(--primary-light);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px -3px 15px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 15px;
  z-index: 1050;
  margin: 5px;

  backdrop-filter: blur(10px); /* Glass effect */
  -webkit-backdrop-filter: blur(20px); /* Safari support */
  transition: all 0.3s ease-in-out;
}
.edit-modal {
  overflow-y: auto;
}
