/* FilterSortBar.css */

.filter-sort-bar {
    margin: 20px 0;
    position: relative;
}

.collapse-toggle {
    position: absolute;
    top: -10px; /* Plassering av ikonet */
    right: 10px; /* Plasser ikonet på høyre side */
    cursor: pointer;
    color: var(--primary); /* Bruk primærfarge */
    display: flex; /* Plasser ikon og tekst på linje */
    align-items: center;
}

.toggle-text {
    margin-left: 5px; /* Litt avstand mellom ikon og tekst */
    font-size: 0.9rem; /* Juster tekststørrelse for å holde den diskret */
}

.form-group {
    margin-bottom: 15px;
}

.label {
    font-weight: bold;
}

.input {
    border: 2px solid var(--primary);
    border-radius: 8px;
    padding: 10px;
    font-size: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input:focus {
    outline: none;
    border-color: var(--primary-dark);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
