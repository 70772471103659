/* Sørg for at modal-dialog er sentrert */
.information-modal .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto; /* Sentrer horisontalt */
    min-height: calc(100vh - 1rem); /* Full høyde minus margin */
}

/* Sentrer innholdet i modalen */
.information-modal .modal-content {
    margin: auto; /* Sentraliser vertikalt */
    max-width: 600px; /* Begrens bredde */
    border-radius: 8px; /* Runde hjørner */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtil skygge */
}

/* Stiler for modal-header */
.information-modal .modal-header {
    display: flex;
    align-items: center; /* Juster ikon og tekst vertikalt */
    background-color: #007bff;
    color: #fff;
    font-weight: bold;
    padding: 10px 15px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

/* Stiler for modal-body */
.information-modal .modal-body {
    font-size: 1rem;
    color: #333;
    line-height: 1.5;
    padding: 20px;
}

/* Stiler for modal-footer */
.information-modal .modal-footer {
    text-align: right;
    padding: 10px 15px;
    border-top: 1px solid #ddd;
}
.information-modal .modal-body ul {
    list-style: disc;
    margin-left: 1.5rem;
}

.information-modal .modal-body ul li {
    margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
    .information-modal {
        max-width: 90%; /* Reduser bredde på mindre skjermer */
        padding: 15px; /* Juster innvendig margin */
    }
}

/* Stil for lukkeknappen */
.information-modal .modal-header .close {
    font-size: 1.5rem; /* Større ikon for bedre synlighet */
    color: #fff; /* Gjør ikonet hvitt for å matche headeren */
    opacity: 0.8; /* Subtil gjennomsiktighet */
    transition: opacity 0.3s; /* Myk overgang ved hover */
    cursor: pointer; /* Markør for interaktivitet */
}

.information-modal .modal-header .close:hover {
    opacity: 1; /* Full farge når man hovrer over ikonet */
}

/* Stil for andre ikoner i modal-body */
.information-modal .modal-body .icon {
    font-size: 1.2rem; /* Standard størrelse for ikoner */
    color: #007bff; /* Fargen matcher headeren */
    margin-right: 0.5rem; /* Litt mellomrom mellom ikon og tekst */
    vertical-align: middle; /* Juster ikonets posisjon med teksten */
}

/* Justering for informasjonssymbol */
.information-modal .modal-header .info-icon {
    font-size: 2rem; /* Gjør ikonet enda større */
    color: #ffdd57; /* Bruk en kontrastfarge, som gul */
    margin-right: 0.75rem; /* Sørg for at teksten ikke er for nær ikonet */
}


.information-modal .modal-body .icon.success {
    color: #28a745; /* Grønn for vellykket */
}

.information-modal .modal-body .icon.warning {
    color: #ffc107; /* Gul for advarsler */
}

.information-modal .modal-body .icon.info {
    color: #17a2b8; /* Cyan for informasjon */
}

.information-modal .modal-header .info-icon:hover {
    color: #ffc107; /* Endre til en lysere nyanse */
    transform: scale(1.1); /* Gjør det litt større for interaktiv følelse */
}

.information-modal .modal-body ul {
    list-style: none; /* Fjerner prikkene */
    padding-left: 0; /* Fjerner innrykk */
}

.information-modal .modal-body ul li {
    margin-bottom: 0.5rem; /* Beholder avstand mellom elementer */
    display: flex; /* Sørger for at ikon og tekst justeres pent */
    align-items: center; /* Justerer ikon og tekst vertikalt */
}



