/* ContextListItem.css */

.context-list-item {
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;
    word-break: break-word;
}

.context-list-item:hover {
    background-color: #f8f9fa;
}

.context-list-item.selected {
    background-color: var(--card-selected-bg) !important;
    color: #000000 !important;
    box-shadow: inset 4px 4px 4px #9CD4F2, inset -4px -4px 3px #85C0FF;
}

.context-icon {
    margin-right: 10px;
    font-size: 1.5rem;
    color: var(--primary);
}

.context-details {
    flex-grow: 1;
}

.context-details p {
    font-size: 0.75rem;
}
