.dialog-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Shared card styles */
.thread-list-container,
.messages-container,
.model-list {
  background-color: var(--card-background-color);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-shadow);
  padding: var(--card-padding);
  flex-grow: 1;
  overflow-y: auto;
  max-width: 100%;
  box-sizing: border-box;
}

/* Thread list styles */
.thread-list-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: transform 0.3s ease, width 0.3s ease;
  z-index: 1;
}

.thread-list-hidden {
  transform: translateX(-100%);
  width: 0;
}

.thread-list-visible {
  transform: translateX(0);
  width: 25%;
}

/* Toggle button styles */
.toggle-button {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 2;
  background-color: rgba(13, 110, 253, 0.5); /* Slightly transparent blue */
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
  height: 100px;
  font-weight: 600;
  font-size: 1.5em;
}

/* Message container styles */
.message-container {
  transition: margin-left 0.3s ease;
  width: 100%;
}

/* Message list styles */
.message-list {
  flex-grow: 1;
}

/* Message card styles */
.message-card {
  background-color: var(--background-color);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-shadow);
  margin-bottom: 10px;
  padding: 10px 15px;
}

.message-card .card-text {
  margin-bottom: 0;
}
.threadlist-slide {
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
  will-change: transform;
}

.threadlist-slide-hidden {
  transform: translateX(-100%);
}
