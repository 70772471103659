/* src/components/DialogList.css */

.thread-list {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 90vh;
  max-width: 100%;
  margin-top: 20px;
}

.thread-list-item {
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
  border: 1px solid var(--hover-background-color);
  margin-bottom: 10px;
  border-radius: var(--card-border-radius);
  padding: 15px;
  display: flex;
  align-items: center;
  max-width: 100%;
  box-sizing: border-box;
}

.thread-list-item:hover {
  background-color: #f8f9fa;
  box-shadow: var(--card-shadow);
}

.thread-list-item.active {
  background-color: #007bff;
  color: white;
}

.thread-list-item h5 {
  margin-bottom: 0;
}

.thread-list-item p {
  margin-bottom: 0;
}

.thread-list-item .btn-link {
  padding: 0;
  font-size: 0.9rem;
  color: #007bff;
}

.thread-list-item .btn-link:hover {
  text-decoration: none;
  color: #0056b3;
}
.thread-search {
  background-color: #ededed;
}
/* @media (max-width: 768px) {
    .dialog-search.hide {
      display: none;
    }
  
    .dialog-search.show {
      display: block;
    }
  
  }
   */
