.content-info {
  margin-top: 20px;
  word-break: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  overflow-y: auto; /* Aktiver vertikal rulling */
  padding-right: 10px; /* Unngå overlapping med scrollbar */
  box-sizing: border-box; /* Sikrer padding inkluderes */
}

/* Valgfritt: Stil scrollbar for bedre utseende */
.content-info::-webkit-scrollbar {
  width: 8px;
}

.content-info::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.content-info::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.content-info::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.info-icon {
  font-size: 1.5rem;
  color: var(--primary);
}

.markdown-content {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  max-width: 100%;
  overflow-y: auto;
}

.markdown-content ul,
.markdown-content ol {
  margin-left: 20px;
}

.markdown-content a {
  color: var(--link-color);
  text-decoration: underline;
}
/* ContentInfo.css */

/* Styled Table */
.styled-table {
  overflow-x: auto; /* Add horizontal scroll for wide tables */
  overflow-y: auto;
}

.styled-table table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.styled-table table th,
.styled-table table td {
  padding: 0.75rem;
  text-align: left;
  vertical-align: middle;
  border: 1px solid #dee2e6;
  word-break: break-word !important;
  min-width: 100px;
}

.styled-table table th {
  background-color: #f2f2f2;
  color: #333;
}
@media (max-width: 768px) {
  .styled-table .table-responsive {
    border: 0;
  }

  .styled-table table th,
  .styled-table table td {
    white-space: nowrap;
  }
}
