/* SearchBar.css */

.search-input {
    border: 2px solid var(--primary);
    border-radius: 20px;
    padding: 12px 20px; /* Legg til mer padding inne i feltet */
    width: 100%; /* Gjør søkefeltet fullt bredt */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Myk skygge */
    font-size: 1rem;
    transition: box-shadow 0.2s ease;
    margin: 5px 0; /* Legg til vertikal margin for avstand fra andre elementer */
}

.search-input:focus {
    outline: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Sterkere skygge ved fokus */
}

.search-input::placeholder {
    color: #aaa;
}
